import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { css } from 'styled-components'

import Card from '../components/Card'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import CategoryCard from '../components/CategoryCard'
import { H3 } from '../components/typography/index'

import { categories } from '../utils'

export const query = graphql`
  query($category: String!) {
    postgres {
      items(filter: { category: { equalTo: $category } }) {
        nodes {
          price
          unit
          size
          properties
          nameHe
          nodeId
          nameEn
          nameAr
          model
          imageUrlCompressed
          id
          descriptionHe
          descriptionEn
          descriptionAr
          createdAt
          category
        }
      }
    }
  }
`

const CategoryTemplate = ({
  data: {
    postgres: {
      items: { nodes: products },
    },
  },
}) => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO
        dir={intl.locale === 'en' ? 'ltr' : 'rtl'}
        title={intl.formatMessage({ id: 'title' })}
        lang={intl.locale}
        description={intl.formatMessage({ id: 'meta_desc' })}
      />
      <div
        css={css`
          margin: 10rem 9.5rem;

          @media (max-width: 600px) {
            margin: 5rem 3.5rem;
          }
        `}
      >
        <H3>{intl.formatMessage({ id: 'categories' })}</H3>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            margin-top: 5rem;

            @media (max-width: 600px) {
              margin-top: 3rem;
            }
          `}
        >
          {categories.map(({ name, path }) => (
            <div
              css={css`
                width: 28.5rem;
                margin-bottom: 1.5rem;

                @media (max-width: 600px) {
                  width: 45%;
                }
              `}
            >
              <CategoryCard key={name} name={name} path={path} />
            </div>
          ))}
        </div>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin: 15rem auto;

            @media (max-width: 600px) {
              margin: 6rem auto;
            }
          `}
        >
          {products.length !== 0 ? (
            products.map(node => (
              <Card
                price={node.price}
                name={node[`name_${intl}`]}
                imageUrlCompressed={node.imageUrlCompressed}
                id={node.id}
                category={node.category}
                model={node.model}
                key={node.id}
              />
            ))
          ) : (
            <H3
              css={css`
                margin: 0 auto;
              `}
            >
              {intl.formatMessage({ id: 'no_products' })}
            </H3>
          )}
        </div>
      </div>
    </Layout>
  )
}

CategoryTemplate.propTypes = {
  data: PropTypes.shape({
    postgres: PropTypes.shape({
      items: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
  }).isRequired,
}

export default CategoryTemplate
